export async function checkForServiceWorkerUpdates() {
  if (!('serviceWorker' in navigator)) {
    return false;
  }

  try {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      return false;
    }

    const updateFound = new Promise((resolve) => {
      if (registration.waiting) {
        resolve(true);
      }

      registration.addEventListener('updatefound', () => {
        const installingWorker = registration.installing;
        if (installingWorker) {
          installingWorker.addEventListener('statechange', () => {
            if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
              resolve(true);
            }
          });
        }
      });
    });

    registration.update();

    if(await updateFound){
      return false;
    }
  } catch (error) {
    console.error('Error checking for service worker updates:', error);
    return false;
  }
}
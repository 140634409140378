
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import promise from "redux-promise";
import createSagaMiddleware from "redux-saga";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import reducers from "./reducers";
import rootSaga from "./sagas";
import { loadState, saveState } from "./reducers/local-storage";
import { gnbDarkTheme } from "./shared-components/src/gnb-theme";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import DynamicAppContainer from "./components/dynamic-app-container";
import Typography from "@material-ui/core/Typography";


// Saga

const persistedState = loadState();
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({});

const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(promise, sagaMiddleware)
)(createStore);

// Redux
const store = createStoreWithMiddleware(reducers, persistedState);

store.subscribe(() => {
  const auth = store.getState().authenticate;
  if (auth.remember) {
    saveState({
      authenticate: auth,
    });
  }
});

sagaMiddleware.run(rootSaga);
// Material UI
const muiTheme = createMuiTheme(gnbDarkTheme);

// This is the main render (junk comment to force a new build)
ReactDOM.render(
  <div>
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <Typography component="div" color="textPrimary">
          <DynamicAppContainer />
        </Typography>
      </MuiThemeProvider>
    </Provider>
  </div>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();

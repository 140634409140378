import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import UsersIcon from '@material-ui/icons/Person';
import AssetsIcon from '@material-ui/icons/Apps';
import RolesIcon from '@material-ui/icons/Security';
import AccountsIcon from '@material-ui/icons/BusinessCenter';
import GroupsIcon from '@material-ui/icons/People';
import LocalizedStrings from 'react-localization';
import {AsyncComponent} from "../shared-components/src/components/DynamicAppV2/async-component";
import {copyObject} from "../shared-components/src/utility";
import {
  RECEIVED_GET_USER_CONFIG
} from '../actions';

const availableRoutes = {
  loadingConfig: AsyncComponent(() => import('../components/loading-config-container')),
  login: AsyncComponent(() => import('../components/login/login')),
  dashboard: AsyncComponent(() => import('../components/dashboard')),
  users: AsyncComponent(() => import('../components/users')),
  groups:  AsyncComponent(() => import('../components/groups')),
  roles: AsyncComponent(() => import('../components/roles')),
  assets: AsyncComponent(() => import('../components/assets')),
  accounts: AsyncComponent(() => import('../components/accounts')),
  notAuthorized: AsyncComponent(() => import('../components/not-authorized')),
};

const iconList = {
  DashboardIcon: <DashboardIcon/>,
  UsersIcon: <UsersIcon/>,
  AssetsIcon: <AssetsIcon/>,
  RolesIcon: <RolesIcon/>,
  AccountsIcon: <AccountsIcon/>,
  GroupsIcon: <GroupsIcon/>,
};

const strings = new LocalizedStrings({en: {}});

const initialState = {
  stringTable: {
    en: {
      mainTitle: "Portal",
    }
  },
  appConfig: {
    mainTitleName: "mainTitle",

    menu: [],

    routes: [
      {
        route: '/login',
        index: 'login',
        componentName: 'login',
        params: []
      },
      {
        route: '/not-authorized',
        index: 'notAuthorized',
        componentName: 'notAuthorized',
        params: []
      },
      {
        route: '/',
        index: '',
        componentName: 'loadingConfig',
        params: []
      },
    ],

    fallbackRoute: {route: '/login', index: '',}
  }
};

export default function (state = initialState, action) {

  switch(action.type) {
    case RECEIVED_GET_USER_CONFIG:
      return processConfig(copyObject(action.payload));

    default:
      return processConfig(copyObject(state));
  }
}

const processConfig = (state) => {
  strings.setContent(state.stringTable);

  let appConfig = state.appConfig;

  appConfig.mainTitle = strings[appConfig.mainTitleName];

  appConfig.menu.forEach(item => {
    item.label = strings[item.labelName];
    item.icon = iconList[item.iconName];
  });

  appConfig.routes.forEach(route => {
    route.component = availableRoutes[route.componentName];
  });

  return state;
};
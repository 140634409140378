import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import { strings } from './localization';




const BuildUpdate = (_props) => {

  const handleUpdate = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration && registration.waiting) {
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          registration.waiting.addEventListener('statechange', (event) => {
            if (event.target.state === 'activated') {
              window.location.reload();
            }
          });
        }
      });
    }
  };

  return (
    <Dialog
      open={true}
      disableBackdropClick={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{strings.buildUpdateNotification}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {strings.buildUpdateText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleUpdate} color="primary" autoFocus>
          {strings.updateNow}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BuildUpdate;
